






































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsExhibitor, AnalyticsVisitorWithHits } from "@/types/interfaces";

export default Vue.extend ({
    components: {
        AnalyticsDateControl
    },
    computed: {

        loading(): boolean {
            return analyticsStore.loading;
        },
    
        sponsorId(): string {
            return this.$route.params.sponsorId ? this.$route.params.sponsorId : "";
        },
    
        exhibitorData(): AnalyticsExhibitor{
            return analyticsStore.singleExhibitor;
        },
    
        users(): Array<AnalyticsVisitorWithHits> {
            return this.exhibitorData.users;
        },
    
        sponsorName(): string {
            return this.exhibitorData.sponsorName;
        },
    
        tableHeaders(): Array<string> {
            return [
                "Attendee Id",
                "First Name",
                "Last Name",
                "Company Name",
                "Email",
                "Visits"
            ];
        },
    
        csvFilename(): string {
            return this.sponsorName
                ? `exhibitor-${Vue.prototype.MgSimpleSlugify(this.sponsorName)}.csv`
                : `exhibitor-${this.sponsorId}`;
        },
    
        tableFooter(): Array<string | number> {
            return [
                "Total Visitors",
                `${this.totalVisitors}`,
                "",
                "",
                "Total Visits",
                `${this.totalVisits}`
            ];
        },
    
        totalVisits(): number {
            return this.users.reduce((acc, curr) => {
                return (acc += curr.hits);
            }, 0);
        },

        totalVisitors(): number {
            return this.users.length;
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        },
    
    },
    methods: {
        getData() {
            analyticsStore.getSingleExhibitorVisits(this.sponsorId);
        },
    
        getCsvUrl() {
            const valuesOnly = this.users.map((item) => [
                item.uid,
                item.firstName,
                item.lastName,
                item.companyName,
                item.email,
                item.hits ? `${item.hits}` : "0"
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly, this.tableFooter];
    
            return transformDataToCsvUrl(dataArray);
        }

    },
    mounted() {
        this.getData();
    }

})
